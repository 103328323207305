var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"white--text mt-2",attrs:{"x-small":"","color":"warning","elevation":"0"},on:{"click":function () { return this$1.$router.push({
                  path: '/abstract-campaign',
                }); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-left ")])],1),_c('h2',{staticClass:"mt-2 ml-4 dark--text d-flex"},[_vm._v(" "+_vm._s(_vm.singleAbstractCampaign.name)+" ")])],1),(!_vm.isCampaignCompareActive)?_c('v-btn',{attrs:{"small":"","color":"blue white--text ml-4 mt-3 p-2","dark":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.setCampaignCompareActive($event)}}},[_c('span',{staticClass:"pr-1"},[_vm._v("Comparar")]),_c('v-icon',[_vm._v(" mdi-swap-horizontal-bold ")])],1):_vm._e(),(_vm.isCampaignCompareActive)?_c('v-btn',{attrs:{"small":"","elevation":"0","color":"red white--text ml-4 mt-3 p-2","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.unSetCampaignCompareActive($event)}}},[_c('span',{staticClass:"pr-1"},[_vm._v("Cancelar")]),_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_c('v-data-table',{staticClass:"mt-2",attrs:{"dense":"","headers":[
                { name: 'Versión', value: 'version' },
                { name: 'Fecha de inicio', value: 'startDate' },
                { name: 'Fecha de finalización', value: 'endDate' } ].map(function (i) {
                return { text: i.name, value: i.value };
              }),"items":_vm.processedCampaigns,"show-select":_vm.isCampaignCompareActive,"item-key":"id","multi-sort":"","hide-default-footer":""},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.isCampaignCompareActive)?_c('div',{staticClass:"d-flex justify-space-between pa-2"},[(_vm.selected.length < 2)?_c('span',{staticClass:"\n                small\n                grey--text\n                font-italic font-weight-light\n                text-disabled text-caption\n              "},[_vm._v("*Selecciona al menos dos elementos")]):(_vm.selected.length > 2)?_c('span',{staticClass:"\n                small\n                red--text\n                font-italic font-weight-light\n                text-caption\n              "},[_vm._v("*Solo puedes comparar dos elementos a la vez")]):_c('span'),_c('v-btn',{class:{ grey: _vm.selected.length != 2 },attrs:{"small":"","elevation":"0","color":"blue white--text ml-4 my-3 p-2"},on:{"click":function($event){$event.stopPropagation();return _vm.redirectToCompareCampaignVersions($event)}}},[_c('span',{staticClass:"pr-1"},[_vm._v("Ir a Comparar")]),_c('v-icon',[_vm._v(" mdi-swap-horizontal-bold ")])],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }